import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedHowItWorksPage = lazy(
  () => import('../components/smart/pages/howItWorks/ConnectedHowItWorksPage')
);

const HOW_IT_WORKS: Route = {
  component: ConnectedHowItWorksPage,
  path: PATH.HOW_IT_WORKS,
  isProtected: false,
};

export default [HOW_IT_WORKS];
