import { useQuery } from '@tanstack/react-query';
import { ApiResponseGetLanguages, languageRequest } from 'api/LanguageRequest';
import { queryKeys } from '../../constants/queryKeys';

function useGetLanguages(enabled?: boolean) {
  const QUERY_KEY = [queryKeys.languages];
  return useQuery<ApiResponseGetLanguages[], Error>({
    queryKey: QUERY_KEY,
    queryFn: () => languageRequest.getLanguages(),
    enabled,
  });
}

export default useGetLanguages;
