import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedFaqPage = lazy(
  () => import('../components/smart/pages/faq/ConnectedFaqPage')
);

const FAQ: Route = {
  component: ConnectedFaqPage,
  path: PATH.FAQ,
  isProtected: false,
};

export default [FAQ];
