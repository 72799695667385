import { useEffect, useRef, VideoHTMLAttributes } from 'react';
import * as SC from './styles';
import mediaPlaceholder from '../../../../assets/images/placeholders/MediaPlaceholder.webp';
import classic from '../../../../styles/theme/classic';

export enum Preload {
  AUTO = 'auto',
  METADATA = 'metadata',
  NONE = 'none',
}

interface Props extends VideoHTMLAttributes<HTMLVideoElement> {
  video: string;
  poster?: string;
  muted?: boolean;
  controls?: boolean;
  autoPlay?: boolean;
  playsInline?: boolean;
  preload?: Preload;
  loop?: boolean;
  hidden?: boolean;
  background?: string;
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;

  [rest: string]: unknown;
}

export default function VideoPlayer({
  video,
  poster = mediaPlaceholder,
  controls = true,
  playsInline = true,
  preload = Preload.AUTO,
  muted = true,
  autoPlay = true,
  loop = true,
  hidden = false,
  background = classic.palette.main.black,
  width = '',
  height = '',
  maxWidth = '100%',
  maxHeight = '100%',
  ...rest
}: Props) {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const scrollPosition = useRef<number>(0);

  const isIOS = /iPad|iPhone|iPod/i.test(navigator.userAgent);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isIOSSafari = isIOS && isSafari;

  const captureScrollPosition = () => {
    scrollPosition.current = window.scrollY;
  };

  const handlePlay = () => {
    if (!isIOSSafari && !document.fullscreenElement && videoRef.current) {
      captureScrollPosition();
    }
  };

  const handleExitFullScreen = () => {
    if (!document.fullscreenElement && videoRef.current) {
      window.scrollTo(0, scrollPosition.current);
    }
  };

  const handleDoubleClick = () => {
    if (videoRef.current) {
      if (!document.fullscreenElement) {
        captureScrollPosition();
      } else {
        handleExitFullScreen();
      }
    }
  };

  const handleVideoEnd = () => {
    if (document.fullscreenElement) {
      document
        .exitFullscreen()
        .then(() => {
          window.scrollTo(0, scrollPosition.current);
        })
        .catch((error) => {
          console.error(
            'Error attempting to exit full-screen mode:',
            error.message
          );
        });
    }
  };

  useEffect(() => {
    const currentVideo = videoRef.current;

    if (currentVideo) {
      currentVideo.addEventListener('play', handlePlay);
      currentVideo.addEventListener('dblclick', handleDoubleClick);
      currentVideo.addEventListener('ended', handleVideoEnd);
    }

    return () => {
      currentVideo?.removeEventListener('play', handlePlay);
      currentVideo?.removeEventListener('dblclick', handleDoubleClick);
      currentVideo?.removeEventListener('ended', handleVideoEnd);
    };
  }, []);

  return (
    <SC.Container
      ref={videoRef}
      width={width}
      height={height}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      background={background}
      src={video}
      poster={poster}
      controls={controls}
      playsInline={playsInline}
      muted={muted}
      autoPlay={autoPlay}
      loop={loop}
      hidden={hidden}
      {...rest}
    >
      Your browser does not support HTML5 video.
    </SC.Container>
  );
}
