import { useInfiniteQuery } from '@tanstack/react-query';
import { fileRequest } from 'api/FileRequest';
import { userRequest } from 'api/UserRequest';
import { queryKeys } from '../../constants/queryKeys';

function useGetSearchedUsers(
  queryOptions: {
    limit: number;
    searchText?: string;
  },
  enabled?: boolean
) {
  const QUERY_KEY = [queryKeys.users, queryOptions];
  const { limit, searchText } = queryOptions;
  const fetchSearchedUsers = async ({ pageParam = 0 }) =>
    userRequest.getUsersSearched({
      limit,
      offset: pageParam,
      searchText,
    });

  return useInfiniteQuery({
    queryKey: QUERY_KEY,
    queryFn: fetchSearchedUsers,
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (
        lastPage.results.length === 0 ||
        allPages.length * limit >= lastPage.total ||
        lastPage.total <= limit
      ) {
        return undefined;
      }
      return lastPageParam + limit;
    },
    select: (data) => ({
      pages: data.pages
        .flatMap((res) => res.results)
        .map((user) => ({
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          role: user.role.name,
          profilePhoto: user.profilePicture
            ? fileRequest.getPublicUrl(user.profilePicture)
            : '',
        })),
      pageParams: data.pageParams,
    }),
    enabled,
  });
}
export default useGetSearchedUsers;
