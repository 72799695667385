import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedExminderProfilePage = lazy(
  () =>
    import('../components/smart/pages/exminder/ConnectedExminderProfilePage')
);

const ConnectedExminderUniversePage = lazy(
  () =>
    import('../components/smart/pages/exminder/ConnectedExminderUniversePage')
);

const ConnectedExminderReviewsPage = lazy(
  () =>
    import('../components/smart/pages/exminder/ConnectedExminderReviewsPage')
);

const EXMINDER_VIEW_PROFILE: Route = {
  component: ConnectedExminderProfilePage,
  path: PATH.EXMINDER.VIEW_PROFILE,
  isProtected: false,
  hasMenuBar: true,
};

const EXMINDER_VIEW_UNIVERSE: Route = {
  component: ConnectedExminderUniversePage,
  path: PATH.EXMINDER.VIEW_UNIVERSE,
  isProtected: false,
  hasMenuBar: true,
};

const EXMINDER_VIEW_REVIEWS: Route = {
  component: ConnectedExminderReviewsPage,
  path: PATH.EXMINDER.VIEW_REVIEWS,
  isProtected: false,
  hasMenuBar: true,
};

const EXMINDER_VIEW_PROFILE_BY_SLUG: Route = {
  component: ConnectedExminderProfilePage,
  path: PATH.EXMINDER.VIEW_PROFILE_BY_SLUG,
  isProtected: false,
  hasMenuBar: true,
};

const EXMINDER_VIEW_UNIVERSE_BY_SLUG: Route = {
  component: ConnectedExminderUniversePage,
  path: PATH.EXMINDER.VIEW_UNIVERSE_BY_SLUG,
  isProtected: false,
  hasMenuBar: true,
};

const EXMINDER_VIEW_REVIEWS_BY_SLUG: Route = {
  component: ConnectedExminderReviewsPage,
  path: PATH.EXMINDER.VIEW_REVIEWS_BY_SLUG,
  isProtected: false,
  hasMenuBar: true,
};

export default [
  EXMINDER_VIEW_PROFILE,
  EXMINDER_VIEW_UNIVERSE,
  EXMINDER_VIEW_REVIEWS,
  EXMINDER_VIEW_PROFILE_BY_SLUG,
  EXMINDER_VIEW_REVIEWS_BY_SLUG,
  EXMINDER_VIEW_UNIVERSE_BY_SLUG,
];
