export default {
  paymentFlow: {
    requirements: 'Cerințe',
    discountCode: 'Cod de reducere',
    enterDiscountCodes: 'Introdu aici codurile de reducere pe care le ai',
    accept: 'Accept',
    termsAndConditions: 'Termenii & Condițiile',
    andThe: 'și',
    privacyPolicy: 'Politica de Confidențialitate Exminds',
    document: 'documentul',
    purchaseConfirmation: 'Confirmă achiziția',
    ifYouPressConfirm:
      'Apasă „Confirmă” pentru a fi redirecționat către furnizorul nostru pentru procesarea plății.',
    paymentRejected: 'Ne pare rău!\nPlata ta a fost respinsă!',
    couldntProceed:
      'Nu am putut continua cu procesul de cumpărare al Experienței.',
    insufficientFunds:
      'Am identificat o problemă de fonduri insuficiente în contul tău.',
    badCard: 'Am identificat o problemă cu cardul tău.',
    paymentProvider: 'Am identificat o problemă cu furnizorul nostru de plăți.',
    exmindsIsOnFire: 'Hip-Hip Ura!',
    paymentAccepted: 'Plata ta a fost acceptată!',
    purchasingProceeded: 'Am continuat cu procesul de cumpărare a Experienței.',
    invoiceSentOnEmail:
      'Factura a fost trimisă pe adresa de email asociată contului.',
    findExperience: 'Poți găsi această Experiență accesând:',
    pathToExperience: 'Meniu -> Contul Meu - Explorer -> Calendarul Meu',
    youCanNowTalkToExminder: 'Poți lua legătura cu Exminderul!',
    youCanNowTalkToExplorer: 'Poți lua legătura cu Explorerul!',
    theChatIsAllYours: 'Inițiază discuția cu',
    selectADate: 'și propune-i o dată în care să vă puteți întâlni.',
    theRestIsHistory: 'De aici începe procesul de învățare Exminds!',
    enjoy: 'Succes în procesul de învățare!',
    splitPrice: {
      title: 'ȚINE MINTE!',
      description_one: 'Stripe va arăta prețul total, dar ',
      description_one_bold:
        'îl poți împărți oricând pe jumătate cu un prieten!',
      description_two:
        '\n\nDoar deschide-ți emailul și adaugă adresa de email a prietenului tău pentru a-l anunța!',
    },
    paymentConflict: {
      title: 'Oh, nu!\nCineva a cumpărat această Experiență înaintea ta!',
      description:
        'Nu am putut finaliza procesul de achiziție al Experienței tale.' +
        '\n\n' +
        'Se pare că un alt Explorer a cumpărat Experiența înainte de a se finaliza procesul de plată.' +
        '\n\n' +
        'Contactează-ne la ',
      supportMail: 'support@exminds.com',
      forTheRefund: ' pentru a iniția procesul de returnare al banilor.',
    },
  },
};
