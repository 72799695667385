import styled from 'styled-components';

export const Container = styled.video<{
  background: string;
  width: string;
  height: string;
  maxWidth: string;
  maxHeight: string;
}>`
  background: ${({ background }) => background};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  max-width: ${({ maxWidth }) => maxWidth};
  max-height: ${({ maxHeight }) => maxHeight};
  object-fit: contain;
`;
