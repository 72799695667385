import { Suspense, useEffect, useLayoutEffect, useState } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import ExminderViewClientStore from 'clientStore/ExminderViewClientStore';
import { setAppRole } from 'redux/slices/appViewRole';
import { AppRole } from 'api/types/user';
import useRefreshUserTokens from 'hooks/useRefreshUserTokens';
import { me } from './redux/slices/auth';
import { useAppDispatch } from './hooks/redux';
import routes from './routes';
import AppRoute from './components/smart/AppRoute';
import PATH from './routes/paths';
import LoadingPageLayout from './components/dumb/layouts/IntroductionLayout/LoadingPageLayout';
import useCheckIfAuthUserProfilePicIsCompressing from './hooks/useCheckIfAuthUserProfilePicIsCompressing';
import VideoPreloader from './components/dumb/atomics/VideoPreloader';

function App() {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useCheckIfAuthUserProfilePicIsCompressing();
  useRefreshUserTokens();
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0 });
  }, [location.pathname]);

  const element = useRoutes([
    ...routes.map((route) => ({
      path: route.path,
      element: (
        <AppRoute
          component={route.component}
          isProtected={route.isProtected}
          redirectIfAuthenticated={route.redirectIfAuthenticated}
          isOnboarding={route.isOnboarding}
          hasMenuBar={route.hasMenuBar}
        />
      ),
    })),
    { path: '*', element: <Navigate to={PATH.INDEX} replace /> },
  ]);

  useEffect(() => {
    dispatch(me()).then(() => setIsLoading(false));

    dispatch(
      setAppRole(
        ExminderViewClientStore.getExminderView()
          ? AppRole.ContentCreator
          : AppRole.Consumer
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <LoadingPageLayout />;
  }

  return (
    <Suspense fallback={<LoadingPageLayout />}>
      <VideoPreloader />
      {element}
    </Suspense>
  );
}

export default App;
