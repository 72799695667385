export class SocketClient extends WebSocket {
  static EVENTS = Object.freeze({
    CREATE_MESSAGE: 'chat:create_message',
    GET_ALL_MESSAGES: 'chat:get_channel_messages',
    SET_LAST_READ_MESSAGE: 'chat:set_last_read_message',
    DELETE_MESSAGE: 'chat:delete_message',
  });

  sendChatMessage(
    message: string,
    fileIds: string[],
    participantId: string,
    channelId: string
  ) {
    const data = { message, fileIds, participantId, channelId };

    this.send(
      JSON.stringify({ event: SocketClient.EVENTS.CREATE_MESSAGE, data })
    );
  }

  getAllChatMessages(channelId: string, limit: number, offset: number) {
    const data = { channelId, limit, offset };

    this.send(
      JSON.stringify({ event: SocketClient.EVENTS.GET_ALL_MESSAGES, data })
    );
  }

  setLastReadMessage(messageId: string, participantId: string) {
    const data = { messageId, participantId };

    this.send(
      JSON.stringify({ event: SocketClient.EVENTS.SET_LAST_READ_MESSAGE, data })
    );
  }

  deleteMessage(messageId: string) {
    const data = { messageId };

    this.send(
      JSON.stringify({ event: SocketClient.EVENTS.DELETE_MESSAGE, data })
    );
  }
}
