export default {
  experienceInviteGuest: {
    title: 'Spune-ne cine se va alătura ție!',
    description:
      'Introdu adresa de email a persoanei cu care intenționezi să împărtășești această Experiență.' +
      '\n\n' +
      'Aceasta va primi toți pașii următori pe adresa furnizată.',
    input: {
      placeholder: 'Introdu adresa de email...',
    },
    button: 'Trimite',
    info: {
      title:
        'Adu un prieten să experimenteze alături de tine și împărțiți prețul la jumătate!',
      description:
        'Experiențele sunt menite să fie împărtășite — fie că împărțiți costul sau vrei să surprinzi un prieten, după achiziție, verifică-ți emailul pentru a adăuga adresa lui.',
    },
    splitPrice: 'Împarte prețul cu un prieten',
  },
};
