export default {
  profile: {
    myProfile: 'Profilul Meu',
    editProfile: 'Editează Profilul',
    activeExperiences: 'Experiențe Active',
    previousExperiences: 'Experiențe Completate',
    mostPopular: 'Cele Mai Populare',
    receivedReview: 'Recenzie Primită',
    memberSince: 'Membru Din',
    addWork: 'Adaugă Muncă',
    addEducation: 'Adaugă Educație',
    addDescription: 'Adaugă Descriere',
    biography: 'Biografie',
    domains: 'Domenii',
    hobbies: 'Hobby-uri',
    nativeLanguage: 'Limba Maternă',
    otherLanguages: 'Alte Limbi Cunoscute',
    languages: 'Limbi',
    testimonials: 'Testimoniale',
    milestones: 'Repere',
    awards: 'Premii',
    videoTestimonials: 'Testimoniale Video',
    editLanguages: 'Editează limbile tale',
    chooseUpToSevenLanguages: 'Alege până la 7 limbi',
    workExperience: 'Experiență de Muncă',
    education: 'Educație',
    jobRole: 'Rolul Jobului',
    location: 'Locație',
    rating: 'Rating',
    tabs: {
      workEducation: 'Muncă &\nEducație',
      myUniverse: 'Universul\nMeu',
      reviews: 'Recenzii',
    },
    noActiveExperience: {
      messageAuthUser: 'Nu ai nicio Experiență activă',
      messageUser: 'Acest Exminder nu are Experiențe active',
    },
    noReviews: {
      messageAuthUser: 'Nu ai primit niciun testimonial',
      messageUser: 'Acest Exminder nu a primit niciun testimonial',
    },
    missingInformation: {
      messageAuthUser: 'Nu ai adăugat informații în această secțiune',
      messageUser:
        'Exminderul nu a adăugat nicio informație în această secțiune',
    },
    missingInformationCard:
      'Nu au fost adăugate informații în această secțiune',
    editFromPersonalDetails:
      ' Editează această secțiune din ”Detalii Personale”',
    mentorOf: 'Mentor pentru',
    people: 'persoane',
    wantedBy: 'Dorit de',
    makeARequest: 'Fă o Cerere',
    seeExperiences: 'Vezi Experiențele',
    about: 'Despre',
    source: 'Sursa',
    noExmindsAccount:
      'Această persoană nu are în prezent cont pe aplicația Exminds.',
    doYouWantToBringThisPerson:
      'Ești interesat să cunoști această persoană în viața reală?',
    requestTheProfile:
      'Trimite o solicitare pentru a o aduce în Universul Exminds!',
    requestSent: 'Cererea ta a fost trimisă!',
    youAreThe: 'Ești',
    explorerInLine: 'Explorer la rând!',
    exploreMore: 'Până atunci de ce nu explorezi mai mult?',
  },
};
