export default {
  sidebarMenu: {
    myProfile: 'My Profile',
    guestProfile: 'Guest Profile',
    continueOnboarding: 'Continue Onboarding',
    accountSettings: 'Account Settings',
    myCalendar: 'My Calendar',
    statistics: 'Statistics',
    favorites: 'Favorites',
    aboutTheApp: 'About the App',
    moneyWithExminds: 'Money with Exminds',
    exmindsPartners: 'Exminds Partners',
    invoices: 'Invoices',
    howItWorks: 'How it works?',
    faq: 'FAQ',
    communityGuidelines: 'Community Guidelines',
    feedback: 'Send us your feedback!',
    termsAndConditions: 'Terms & Conditions',
    privacyPolicy: 'Privacy Policy',
    changeRole: {
      areYouSure: 'Are you sure you want to change your role?',
      beCautious: 'Be cautious, this can literally change your life!',
    },
    completeProfile: {
      exminder: {
        youAreAnExminder: 'You’re an Exminder now',
        complete: 'Complete your Exminder Profile',
        workExperience: 'Work Experience',
        education: 'Education',
        milestones: 'Milestones',
        awards: 'Awards',
        stripe: 'Stripe Account',
      },
      explorer: {
        youAreAnExplorer: 'You’re an Explorer now',
        complete: 'Complete your Explorer Profile',
        biography: 'Biography',
        education: 'Education',
        jobRole: 'Job Role',
        otherInformation:
          'All the other information has been provided from your Exminder account',
      },
      fillIn: 'You still have a few more things to fill in:',
      button: 'Complete Profile',
    },
  },
};
