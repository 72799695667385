import { ReactNode, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import PATH from 'routes/paths';
import { ExperienceStatus } from 'uiModels/enums';
import {
  contentCreatorSlug,
  experienceStatus,
  userId,
} from 'routes/paths/constants';
import { userRequest } from 'api/UserRequest';
import { cvRequest } from 'api/CVRequest';
import { AppRole, OnboardingStatus } from 'api/types/user';
import { setAppRole } from 'redux/slices/appViewRole';
import useSetQueryParams from 'hooks/useSetQueryParams';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { logout, me } from '../../../redux/slices/auth';
import SideBarMenuLayout from '../../dumb/layouts/SideBarMenuLayout';
import ConnectedMenuBar from './ConnectedMenuBar';
import * as SC from './styles';
import {
  appViewRole,
  authUserHasReferralAccountSelector,
  authUserOnboardingIsSkipedSelector,
  authUserOnboardingStatusSelector,
  authUserSelector,
  isAuthenticatedSelector,
  isAuthUserProfilePicCompressing,
} from '../../../redux/slices/selectors';
import { fileRequest } from '../../../api/FileRequest';
import { ExminderRequiredInformation } from '../../dumb/layouts/SideBarMenuLayout/Steps/ExminderCompleteProfileStep';
import { ExplorerRequiredInformation } from '../../dumb/layouts/SideBarMenuLayout/Steps/ExplorerCompleteProfileStep';
import useInAppNavigate from '../../../hooks/useInAppNavigate';
import avatarPlaceholder from '../../../assets/images/placeholders/ProfilePicEmpty.webp';
import TypeChecker from '../../../helpers/classes/TypeChecker';

interface MainLayoutProps {
  children: ReactNode;
}

export default function MainLayout({ children }: MainLayoutProps) {
  const dispatch = useAppDispatch();
  const { navigate } = useInAppNavigate();
  const { navigateWithSearch } = useSetQueryParams();
  const { t } = useTranslation();

  const authUser = useAppSelector(authUserSelector);
  const hasReferralAccount = useAppSelector(authUserHasReferralAccountSelector);
  const appRole = useAppSelector(appViewRole);
  const isCompressing = useAppSelector(isAuthUserProfilePicCompressing);
  const isOnboardingSkipped = useAppSelector(
    authUserOnboardingIsSkipedSelector
  );
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);
  const onboardingStatus = useAppSelector(authUserOnboardingStatusSelector);

  const [openSidebarMenu, setOpenSidebarMenu] = useState(false);
  const [exminderRequiredInformation, setExminderRequiredInformation] =
    useState<ExminderRequiredInformation>({
      hasWorkExperience: false,
      hasEducation: false,
      hasMilestones: false,
      hasAwards: false,
      isStripeRegistered: false,
    });
  const [explorerRequiredInformation, setExplorerRequiredInformation] =
    useState<ExplorerRequiredInformation>({
      hasBiography: false,
      hasEducation: false,
      hasJobRole: false,
    });
  const [changeRoleIsLoading, setChangeRoleIsLoading] = useState(false);

  const sidebarMenuCloseHandler = () => {
    setOpenSidebarMenu(false);
  };
  const sidebarMenuOpenHandler = () => {
    setOpenSidebarMenu(true);
  };
  const handleNavigateProfile = () => {
    if (!isAuthenticated) {
      navigate(PATH.AUTH.SIGN_IN);
      return;
    }
    if (
      isOnboardingSkipped &&
      onboardingStatus !== OnboardingStatus.ProfilePictureSet
    ) {
      navigate(PATH.ONBOARDING.CHAT);
      return;
    }
    if (appRole === AppRole.ContentCreator) {
      const path = authUser.slug
        ? PATH.EXMINDER.VIEW_PROFILE_BY_SLUG.replace(
            contentCreatorSlug,
            authUser.slug
          )
        : PATH.EXMINDER.VIEW_PROFILE.replace(userId, authUser.id ?? '');

      navigate(path);
    } else {
      navigate(PATH.EXPLORER.VIEW_ONE.replace(userId, authUser.id ?? ''));
    }
    sidebarMenuCloseHandler();
  };

  const handleNavigateAccountSettings = () => {
    navigate(PATH.ACCOUNT_SETTINGS.INDEX);
    sidebarMenuCloseHandler();
  };

  const handleNavigateMyCalendar = () => {
    if (appRole === AppRole.ContentCreator) {
      navigateWithSearch(PATH.EXMINDER.MY_EXPERIENCES, [
        {
          key: experienceStatus,
          value: ExperienceStatus.Upcoming,
        },
      ]);
    } else {
      navigateWithSearch(PATH.EXPLORER.MY_EXPERIENCES, [
        {
          key: experienceStatus,
          value: ExperienceStatus.Upcoming,
        },
      ]);
    }
    sidebarMenuCloseHandler();
  };

  const handleNavigateFavorites = () => {
    navigate(PATH.EXPERIENCE.FAVORITES);
    sidebarMenuCloseHandler();
  };

  const handleNavigateMoneyWithExminds = () => {
    navigate(PATH.MONEY_WITH_EXMINDS);
    sidebarMenuCloseHandler();
  };

  const handleNavigateReferralDashboard = () => {
    navigate(PATH.REFERRAL_DASHBOARD);
    sidebarMenuCloseHandler();
  };

  const handleNavigateInvoices = () => {
    navigate(PATH.INVOICES);
    sidebarMenuCloseHandler();
  };

  const handleNavigateHowItWorks = () => {
    navigate(PATH.HOW_IT_WORKS);
    sidebarMenuCloseHandler();
  };

  const handleNavigateCommunityGuidelines = () => {
    navigate(PATH.COMMUNITY_GUIDELINES);
    sidebarMenuCloseHandler();
  };

  const handleNavigateFaq = () => {
    navigate(PATH.FAQ);
    sidebarMenuCloseHandler();
  };

  const handleNavigateFeedback = () => {
    navigate(PATH.SEND_FEEDBACK);
    sidebarMenuCloseHandler();
  };

  const handleNavigateTermsAndConditions = () => {
    navigate(PATH.TERMS_AND_CONDITIONS);
    sidebarMenuCloseHandler();
  };

  const handleNavigatePrivacyPolicy = () => {
    navigate(PATH.PRIVACY_POLICY);
    sidebarMenuCloseHandler();
  };

  const changeRole = () => {
    dispatch(
      setAppRole(
        appRole === AppRole.ContentCreator
          ? AppRole.Consumer
          : AppRole.ContentCreator
      )
    );
  };

  const handleChangeAppRole = () => {
    if (authUser.role?.name === AppRole.Consumer) {
      setChangeRoleIsLoading(true);
      userRequest
        .upgradeToContentCreator()
        .then(() => changeRole())
        .then(() => dispatch(me()))
        .catch(() => {
          toast.error(t(`toast.error`) as string, {
            toastId: t(`toast.error`),
          });
        })
        .finally(() => setChangeRoleIsLoading(false));
    } else {
      changeRole();
    }
  };

  const handleCompleteProfile = () => {
    if (appRole === AppRole.ContentCreator) {
      navigate(PATH.EXMINDER.VIEW_PROFILE.replace(userId, authUser.id ?? ''));
    } else {
      navigate(PATH.EXPLORER.VIEW_ONE.replace(userId, authUser.id ?? ''));
    }
    sidebarMenuCloseHandler();
  };

  useEffect(() => {
    if (!isAuthenticated || isOnboardingSkipped) {
      return;
    }
    if (appRole === AppRole.ContentCreator) {
      userRequest.getExplorerProfile(authUser.id ?? '').then((res) => {
        setExplorerRequiredInformation({
          hasBiography: Boolean(res.consumerProfile?.biography),
          hasJobRole: Boolean(res.consumerProfile?.jobRole),
          hasEducation: Boolean(res.consumerProfile?.education),
        });
      });
    } else {
      cvRequest.getCvData(authUser.id ?? '').then((res) => {
        setExminderRequiredInformation({
          hasAwards: Boolean(res.awards?.length > 0),
          hasMilestones: Boolean(res.milestones?.length > 0),
          hasEducation: Boolean(res.educations?.length > 0),
          hasWorkExperience: Boolean(res.workExperiences?.length > 0),
          isStripeRegistered: Boolean(
            authUser.metadata?.stripe?.seller?.accountId
          ),
        });
      });
    }
  }, [isAuthenticated, isOnboardingSkipped, appRole]);

  let avatarImage = avatarPlaceholder;
  if (!isCompressing && TypeChecker.isFileResponse(authUser.profilePicture)) {
    avatarImage = fileRequest.getPublicUrl(authUser.profilePicture);
  }

  return (
    <>
      {children}
      <SC.MenuContent>
        <ConnectedMenuBar onMenuClick={sidebarMenuOpenHandler} />
      </SC.MenuContent>
      <SideBarMenuLayout
        user={authUser}
        avatar={avatarImage}
        isProfileImageCompressing={Boolean(isCompressing)}
        open={openSidebarMenu}
        onClose={sidebarMenuCloseHandler}
        onMyProfileClick={handleNavigateProfile}
        onAccountSettingsClick={handleNavigateAccountSettings}
        onMyCalendarClick={handleNavigateMyCalendar}
        onFavoritesClick={handleNavigateFavorites}
        onStatisticsClick={handleNavigateAccountSettings}
        onMoneyWithExmindsClick={handleNavigateMoneyWithExminds}
        onReferralDashboardClick={handleNavigateReferralDashboard}
        onInvoicesClick={handleNavigateInvoices}
        onHowItWorksClick={handleNavigateHowItWorks}
        onCommunityGuidelinesClick={handleNavigateCommunityGuidelines}
        onFaqClick={handleNavigateFaq}
        onFeedbackClick={handleNavigateFeedback}
        onTermsAndConditionsClick={handleNavigateTermsAndConditions}
        onPrivacyPolicyClick={handleNavigatePrivacyPolicy}
        onLogout={() => dispatch(logout())}
        onChangeAppRole={handleChangeAppRole}
        onCompleteProfile={handleCompleteProfile}
        exminderRequiredInformation={exminderRequiredInformation}
        explorerRequiredInformation={explorerRequiredInformation}
        isExminder={appRole === AppRole.ContentCreator}
        isAuthenticated={isAuthenticated}
        isOnboardingSkipped={
          isOnboardingSkipped &&
          onboardingStatus !== OnboardingStatus.ProfilePictureSet
        }
        isChangeRoleLoading={changeRoleIsLoading}
        hasReferralAccount={Boolean(hasReferralAccount)}
      />
      {openSidebarMenu && (
        <Helmet>
          <meta name="theme-color" content="#000000" />
        </Helmet>
      )}
    </>
  );
}
