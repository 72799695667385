export default {
  experienceActions: {
    title: 'Experiența "{{experienceTitle}}" are următorul status',
    resume: 'Apasă pentru a relua',
    enum: {
      active: 'Activă',
      draft: 'Ciornă',
      upcoming: 'În Viitor',
      inProgress: 'În Desfășurare',
      completed: 'Completată',
      expired: 'Expirată',
      canceled: 'Anulată',
      shadowBan: 'În Așteptarea Aprobării',
    },
    statusDescription: {
      active: 'Experiența este afișată pe profilul tău.',
      draft: 'Experiența nu este afișată pe profilul tău.',
      upcoming:
        'Experiența a fost achiziționată și este programată să aibă loc.',
      inProgress: 'Experiența se desfășoară chiar acum.',
      completed: 'Experiența s-a încheiat cu validarea codului PIN.',
      expired: 'Experiența s-a încheiat fără validarea codului PIN.',
      canceled: 'Experiența nu mai este programată să aibă loc.',
      shadowBan:
        'Experiența nu este afișată în feed până când nu este revizuită de către echipa noastră.',
    },
    action: {
      active: 'Activează',
      draft: 'Salvează ca Ciornă',
      edit: 'Editează Experiența',
      duplicate: 'Duplică Experiența',
      delete: 'Șterge Experiența',
      cancel: 'Anulează Experiența',
      description: {
        active: 'Experiența va fi activă și va apărea pe profilul tău.',
        draft:
          'Experiența va fi inactivă și nu va mai fi văzută pe profilul tău.',
        edit: 'Experiența nu a fost achiziționată. Poți încă să editezi detaliile.',
        duplicate: 'Creează o copie a Experienței pregătită pentru editare.',
        delete: 'Experiența nu a fost achiziționată încă și poate fi ștearsă.',
        cancel:
          'Experiența a fost achiziționată. Poți să anulezi acest lucru și Explorerul va primi suma achitată înapoi. La anulare, o taxă de comision va fi percepută din contul tău.',
      },
      makeActiveConfirmation: {
        title: 'Confirmare Experiență Activă',
        description_one:
          'Înainte de a publica Experiența, echipa noastră o va analiza pentru a se asigura că respectă Regulile de Conținut ale Comunității.',
        description_two:
          'Până când va fi aprobată, uite la ce poți să te aștepți:',
        item_one: 'Experiența nu va apărea pe profilul tău',
        item_two: 'Nu vei putea Edita, Duplica sau Șterge Experiența',
        cancel: 'Înapoi',
        confirmation: 'Activează',
      },
      makeDraftConfirmation: {
        title: 'Confirmare Ciornă',
        description_one:
          'În urma acestei acțiuni, Experiența va fi ascunsă de pe profilul tău până când o reactivezi.',
        description_two:
          'Vei putea modifica informațiile, fără a fi necesară aprobarea din partea echipei noastre.',
        cancel: 'Înapoi',
        confirmation: 'Creează Ciornă',
      },
    },
    registerAccountInfo: {
      title: 'Încă câțiva pași',
      firstDescription:
        'Este necesar să completezi informațiile de mai jos pentru a publica prima ta Experiență',
      secondDescription:
        'Experiența va fi ascunsă până când vei face modificările.',
      underlineText: 'Înregistrează-ți Contul',
    },
    registerPhoneInfo: {
      setPhoneNumber: 'Numărul tău de telefon nu este setat',
      firstDescription:
        'Pentru a te înregistra cu Stripe mai întâi trebuie să îți setezi numărul de telefon.',
      secondDescription:
        'Experiența va fi ascunsă până când vei face modificările.',
      underlineText: 'Setează Numărul de Telefon',
    },
    requiredInformation: {
      setPhone: {
        title: 'Informații de Contact',
        subtitle: 'Setează-ți numărul de telefon',
      },
      stripe: {
        title: 'Înregistrare Stripe',
        subtitle: 'Mai multe câmpuri trebuie completate',
      },
      phone: {
        title: 'Confirmare Telefon',
        subtitle: 'Trebuie să verificăm identitatea ta',
      },
      requirementsBenefits: {
        title: 'Cerințe & Beneficii',
        subtitle: 'Definește-ți nevoile și valoarea pe care o oferi',
      },
    },
    disabled:
      'Experiența a fost achiziționată. Nu poți efectua această acțiune.',
    areYouSureDelete: 'Ești sigur că vrei să ștergi această Experiență?',
    areYouSureCancel: 'Ești sigur că vrei să anulezi această Experiență?',
    uponCancellation:
      'La anulare, o taxă de comision va fi percepută din contul tău și Explorerul va primi suma achitată înapoi.',
  },
};
