import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ProfileNotFound = lazy(
  () => import('../components/smart/pages/errors/ProfileNotFound')
);

const ExperienceNotFound = lazy(
  () => import('../components/smart/pages/errors/ExperienceNotFound')
);

const PROFILE_NOT_FOUND: Route = {
  component: ProfileNotFound,
  path: PATH.ERROR.PROFILE_NOT_FOUND,
  isProtected: true,
};

const EXPERIENCE_NOT_FOUND: Route = {
  component: ExperienceNotFound,
  path: PATH.ERROR.EXPERIENCE_NOT_FOUND,
  isProtected: true,
};

export default [PROFILE_NOT_FOUND, EXPERIENCE_NOT_FOUND];
