import * as SC from './styles';
import VideoPlayer from '../VideoPlayer';
import usePreloadMindFeed from '../../../../hooks/mindFeed/usePreloadMindFeed';

export default function VideoPreloader() {
  const { videos } = usePreloadMindFeed();

  if (videos.length === 0) {
    return null;
  }

  return (
    <SC.Container>
      {videos.map((source: string) => (
        <VideoPlayer key={source} video={source} width="0px" height="0px" />
      ))}
    </SC.Container>
  );
}
