export const queryKeys = {
  activeExperiences: 'activeExperiences',
  cities: 'cities',
  completedExperiences: 'completedExperiences',
  countries: 'countries',
  cvData: 'cvData',
  domains: 'domains',
  explorerExperiences: 'explorerExperiences',
  explorerProfile: 'explorerProfile',
  experienceViewOne: 'experienceViewOne',
  experiences: 'experiences',
  favoritedExperience: 'favoritedExperience',
  favorites: 'favorites',
  hobbies: 'hobbies',
  invoices: 'invoices',
  languages: 'languages',
  mindFeedExperiences: 'mindFeedExperiences',
  myExperiences: 'myExperiences',
  popularPersons: 'popularPersons',
  referralAccount: 'referralAccount',
  referralHistory: 'referralHistory',
  requestsToJoin: 'requestsToJoin',
  reviews: 'reviews',
  users: 'users',
  userId: 'userId',
};
