import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from '../Button';
import ButtonWrapper from '../ButtonWrapper';

const zIndex = {
  image: 1,
  overlay: 2,
  imageDate: 3,
  link: 4,
  button: 5,
};

const determineHeight = (
  extendedCardHeight: boolean,
  showUser: boolean
): string => {
  if (extendedCardHeight) {
    return showUser ? '618px' : '537px';
  }
  if (showUser) {
    return '563px';
  }
  return '482px';
};

export const Container = styled.div<{
  $extendedCardHeight: boolean;
  $showUser: boolean;
}>`
  position: relative;
  max-width: 350px;
  min-width: 280px;
  width: 100%;
  height: ${({ $extendedCardHeight, $showUser }) =>
    determineHeight($extendedCardHeight, $showUser)};
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05);
  border-radius: 29px;
  background-color: ${({ theme }) => theme.palette.main.white};
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  word-break: break-word;
  text-decoration: none;
`;

export const LinkOverlay = styled(Link)`
  position: absolute;
  z-index: ${zIndex.link};
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 29px;
`;

export const CardContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  position: relative;
  max-width: 350px;
  max-height: 350px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 29px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;

export const Image = styled.img`
  position: relative;
  border-radius: 29px;
  width: 100%;
  height: 100%;
  z-index: ${zIndex.image};
  object-fit: cover;
  object-position: 50% 0;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
`;

export const Overlay = styled.div`
  position: absolute;
  z-index: ${zIndex.overlay};
  width: 100%;
  height: 100%;
  border-radius: 29px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.75) 0%,
    rgba(0, 0, 0, 0.25) 20%,
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0.25) 80%,
    rgba(0, 0, 0, 0.75) 100%
  );
`;

export const ImageDateContainer = styled.div`
  position: absolute;
  border-radius: 29px;
  z-index: ${zIndex.imageDate};
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding: 20px 20px 0 20px;
`;

export const ExperienceTitle = styled.h3`
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.pxToRem(2)};
  line-height: ${({ theme }) => theme.typography.pxToRem(6)};
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.main.white};
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
`;

export const UserAvatarContainer = styled.div`
  width: 60px;
  height: 60px;
`;

export const UserAvatar = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 10px;
  object-fit: cover;
  object-position: 50% 0;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
`;

export const ExperiencePriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 5px;
`;

export const PricesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 3px;
`;

export const CurrentPriceContainer = styled.div`
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.pxToRem(-2)};
  line-height: ${({ theme }) => theme.typography.pxToRem(2)};
  color: ${({ theme }) => theme.palette.main.black};
  text-align: left;
`;

export const OriginalPriceContainer = styled(CurrentPriceContainer)`
  text-decoration: line-through;
  opacity: 0.5;
  margin-right: 5px;
`;

export const CurrencyContainer = styled.span``;

export const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
  @media only screen and (max-width: 390px) {
    gap: 15px;
  }
`;

export const Detail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const DetailText = styled.p`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(-2)};
  line-height: ${({ theme }) => theme.typography.pxToRem(2)};
  color: ${({ theme }) => theme.palette.main.black};
  text-align: left;
`;

export const IconContainer = styled.div`
  svg {
    path {
      fill: ${({ theme }) => theme.palette.main.white};
    }
    width: 24px;
    height: 24px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ColoredIconContainer = styled(IconContainer)`
  svg {
    path {
      fill: ${({ theme }) => theme.palette.main.marine};
    }
  }
`;

export const DateRow = styled.div`
  width: 100%;
  max-width: 262px;
  margin: 0 auto;
`;

export const DateContainer = styled.div`
  background: linear-gradient(
    180deg,
    rgba(0, 175, 185, 0.7) 0%,
    rgba(0, 129, 170, 0.7) 100%
  );
  padding: 6px;
  border-radius: 5px 5px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  overflow: hidden;
  white-space: pre-wrap;
`;

export const DateText = styled.p`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.typography.pxToRem(-2)};
  line-height: ${({ theme }) => theme.typography.pxToRem(2)};
  color: ${({ theme }) => theme.palette.main.white};
`;

export const ExperienceDetailsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  padding: 20px;
  border-radius: 0 0 24px 24px;
`;

export const ExperienceDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export const StyledButton = styled(Button)`
  position: relative;
  z-index: ${zIndex.button};
  width: 100%;
  max-width: 100%;
  text-transform: uppercase;
  text-decoration: none;
`;

export const ButtonIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 24px;
    height: 24px;
    path {
      fill: ${({ theme }) => theme.palette.main.white};
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  color: ${({ theme }) => theme.palette.main.white};
  background: ${({ theme }) => theme.palette.main.cyan};
  opacity: 0.5;
`;

export const UserDetails = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const UserDetailsContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const UserNameWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UserNameContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
`;

export const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ShowProfileButton = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.typography.pxToRem(-4)};
  line-height: ${({ theme }) => theme.typography.pxToRem(0)};
  color: ${({ theme }) => theme.palette.main.black};
  text-decoration-line: underline;
  padding: 3px 0;
`;

export const BlackRegularBoldText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.pxToRem(-2)};
  line-height: ${({ theme }) => theme.typography.pxToRem(2)};
  color: ${({ theme }) => theme.palette.main.black};
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
`;

export const CompanyRoleText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.typography.pxToRem(-4)};
  line-height: ${({ theme }) => theme.typography.pxToRem(0)};
  color: ${({ theme }) => theme.palette.main.black};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
`;

export const CompanyNameText = styled.div`
  font-style: italic;
  font-weight: 400;
  font-size: ${({ theme }) => theme.typography.pxToRem(-4)};
  line-height: ${({ theme }) => theme.typography.pxToRem(0)};
  color: ${({ theme }) => theme.palette.main.black};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
`;

export const ExplorerText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.typography.pxToRem(-4)};
  line-height: ${({ theme }) => theme.typography.pxToRem(0)};
  color: ${({ theme }) => theme.palette.main.black};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
`;

export const UnboughtExperiencePlaceholder = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`;

export const StyledButtonWrapper = styled(ButtonWrapper)`
  position: relative;
  z-index: ${zIndex.button};
`;
