export default {
  toast: {
    account: {
      deleted: 'Contul tău a fost șters cu succes',
      passwordReset: 'Parola ta a fost schimbată cu succes',
      appLanguage: 'Limba aplicației a fost actualizată cu succes',
    },
    payment: {
      unfinishedTransaction: 'Ai o tranzacție nefinalizată',
      paymentMethodFailed: 'Metoda de plată a eșuat',
      findOutMore: 'Află mai multe despre ce s-a întâmplat',
      experienceOutOfStock:
        'Această experiență nu mai este disponibilă, cineva a cumpărat-o înaintea ta!',
    },
    profile: {
      photoUploadSuccess:
        'Noua ta fotografie de profil a fost încărcată cu succes',
      photoUploadError: 'Noua ta fotografie de profil nu a putut fi încărcată',
    },
    experience: {
      activateSuccess: 'Statutul Experienței a fost setat ca activ',
      activateError: 'Statutul Experienței nu a putut fi setat ca activ',
      purchaseDeadlineActivateError:
        'Termenul limită de cumpărare al Experienței trebuie să fie cel puțin 2 ore de acum înainte',
      draftSuccess: 'Statutul Experienței a fost setat ca ciornă',
      draftError: 'Statutul Experienței nu a putut fi setat ca ciornă',
      editSuccess: 'Experiența a fost editată cu succes',
      editError: 'Experiența nu a putut fi editată',
      duplicateSuccess: 'Experiența a fost duplicată cu succes',
      duplicateError: 'Experiența nu a putut fi duplicată',
      cancelSuccess: 'Experiența a fost anulată cu succes',
      cancelError: 'Experiența nu a putut fi anulată',
      deleteSuccess: 'Experiența a fost ștearsă cu succes',
      deleteError: 'Experiența nu a putut fi ștearsă',
      inviteSuccess: 'Invitația a fost trimisă cu succes',
    },
    experienceRequest: {
      request: 'O cerere a fost trimisă Exminderului',
      accept: 'O notificare de acceptare a fost trimisă Explorerului',
      reject: 'O notificare de respingere a fost trimisă Explorerului',
    },
    experienceReportSuccess: 'Ai raportat cu succes această Experiență',
    experienceReport: 'Ai raportat deja această Experiență',
    exminderReport: 'Ai raportat deja acest Exminder',
    phoneNumberAlreadyExists: 'Acest număr de telefon există deja',
    error: 'A apărut o eroare. Te rugăm să încerci mai târziu.',
    tooManyEmailResendRequests: 'O solicitare de retrimire a fost deja trimisă',
    tooManyForgotPasswordRequests:
      'Un email de confirmare pentru resetare a fost deja trimis',
    tooManySmsCodeRequests: 'O solicitare de cod SMS a fost deja trimisă',
    tooManyRequests: 'Prea multe solicitări au fost trimise',
    fileError: 'Fișierul nu a putut fi încărcat',
    resendEmail:
      'Am retrimis un email cu un link de confirmare la adresa ta de email.',
    invalidCredentials: 'Credențiale invalide. Te rugăm să încerci din nou.',
    confirmationCode: 'Un cod de confirmare a fost trimis.',
    charactersLimit:
      'Titlul trebuie să fie mai scurt sau egal cu 30 de caractere. Descrierea trebuie să fie mai scurtă sau egală cu 300 de caractere.',
    experienceLinkToClipboard: 'Link-ul Experienței a fost salvat în clipboard',
    referralLinkToClipboard:
      'Link-ul tău de recomandare a fost salvat în clipboard',
    locationNotFoundMap:
      'Această locație nu poate fi găsită. Te rugăm să selectezi una diferită.',
    filesError:
      'Fisierele imagine și video nu au putut fi încărcate.Te rugăm să încerci încă o dată.',
    imageError:
      'Fisierul imagine nu au putut fi încărcat. Te rugăm să încerci încă o dată.',
    videoError:
      'Fisierul video nu au putut fi încărcat. Te rugăm să încerci încă o dată.',
    clipboard: {
      notSupported: 'Salvarea în clipboard nu este suportată',
      error: 'Textul nu a putut fi salvat în clipboard',
    },
  },
};
