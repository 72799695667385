export default {
  experienceActions: {
    title: 'Your "{{experienceTitle}}" Experience is',
    resume: 'Click to resume',
    enum: {
      active: 'Active',
      draft: 'Draft',
      upcoming: 'Upcoming',
      inProgress: 'In Progress',
      completed: 'Completed',
      expired: 'Expired',
      canceled: 'Canceled',
      shadowBan: 'Waiting for Approval',
    },
    statusDescription: {
      active: 'It means your Experience is visible on your profile.',
      draft: 'It means your Experience is not visible on your profile.',
      upcoming:
        'It means your Experience has been purchased and is scheduled to happen.',
      inProgress: 'It means your Experience is happening right now.',
      completed: 'It means your Experience has ended with PIN code validation.',
      expired:
        'It means your Experience has ended without PIN code validation.',
      canceled: 'It means your Experience is no longer scheduled to happen.',
      shadowBan:
        'It means your Experience is not visible on the feed while it is reviewed by our team.',
    },
    action: {
      active: 'Make Active',
      draft: 'Make Draft',
      edit: 'Edit Experience',
      duplicate: 'Duplicate Experience',
      delete: 'Delete Experience',
      cancel: 'Cancel Experience',
      description: {
        active:
          'Your Experience will be active and it will be seen on your profile.',
        draft:
          'Your Experience will be inactive and it will no longer be seen on your profile.',
        edit: 'Your Experience wasn’t purchased. You can still edit details.',
        duplicate: 'You can have a copy of your Experience ready to edit.',
        delete: 'Your Experience wasn’t purchased. You can delete it.',
        cancel:
          'Your Experience has been purchased. You can cancel this and the Explorer will be refunded. Upon cancellation, a commission fee will be charged to your account.',
      },
      makeActiveConfirmation: {
        title: 'Confirm Experience Activation',
        description_one:
          'Before making your Experience active, our team will review it to ensure it aligns with the community guidelines.',
        description_two: "Until approved, here's what to expect:",
        item_one: 'The Experience will not be showing on your profile',
        item_two:
          'You will not be able to Edit, Duplicate or Delete this Experience',
        cancel: 'Go Back',
        confirmation: 'Make Active',
      },
      makeDraftConfirmation: {
        title: 'Confirm Experience Draft',
        description_one:
          'By making this action, the Experience will be hidden from your profile until you reactivate it.',
        description_two:
          'You’ll be able to change the information without waiting for approval from our team.',
        cancel: 'Go Back',
        confirmation: 'Make Draft',
      },
    },
    registerAccountInfo: {
      title: 'A few more steps',
      firstDescription:
        'It is required to complete the information below in order to publish your first Experience',
      secondDescription:
        'Your Experience will be hidden until you make the changes.',
      underlineText: 'Register your Account',
    },
    registerPhoneInfo: {
      setPhoneNumber: 'Your phone number is not set',
      firstDescription:
        'In order to register with Stripe first you must set your phone number.',
      secondDescription:
        'Your Experience will be hidden until you make the changes.',
      underlineText: 'Set Phone Number',
    },

    requiredInformation: {
      setPhone: {
        title: 'Contact Information',
        subtitle: 'Set your phone number',
      },
      stripe: {
        title: 'Stripe Registration',
        subtitle: 'A few more fields require to be filled',
      },
      phone: {
        title: 'Phone Confirmation',
        subtitle: 'We need to verify your identity',
      },
      requirementsBenefits: {
        title: ' Requirements & Benefits',
        subtitle: 'Define your needs and the value you offer',
      },
    },
    disabled:
      'Your Experience has been purchased. You are unable to perform this action.',
    areYouSureDelete: 'Are you sure you want to delete this Experience?',
    areYouSureCancel: 'Are you sure you want to cancel this Experience?',
    uponCancellation:
      'Upon cancellation, a commission fee will be charged to your account and the Explorer will be refunded.',
  },
};
