export default {
  exminderPackage: {
    subtitle: 'Ce învățăm?',
    book: 'Carte',
    preview: {
      seeProfile: 'Vezi\nProfilul',
      presentationImage: 'Imagine de prezentare',
      presentationVideo: 'Prezentare video',
      youCanAlsoSee: 'Puteți vedea și',
    },
    location: 'Locul Întâlnirii',
    directions: 'Indicații',
    description: 'Despre Experiență',
    exminderDisclaimer: 'Declinarea de responsabilitate Exminder',
    exmindsDisclaimer: 'Declinarea de responsabilitate Exminds',
    additionalCosts: 'Costuri suplimentare',
  },
};
