import { createContext, ReactNode, useEffect, useState } from 'react';
import usePlayNotificationSound from 'hooks/usePlayNotificationSound';
import {
  incrementMessagesCount,
  incrementNotificationsCount,
} from 'redux/slices/auth';
import AuthClientStore from '../../clientStore/AuthClientStore';
import eventEmitter, { EVENTS } from '../../eventEmitter';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { isAuthenticatedSelector } from '../../redux/slices/selectors';
import { SocketClient } from '../../websockets/socketClient';
import SocketConfig from '../../websockets/socketConfig';
import { SocketEvent } from '../../websockets/socketEvents';

interface WebSocketProviderProps {
  children: ReactNode;
}

type Socket = SocketClient | null;

export const WebSocketContext = createContext<Socket>(null);

const maxTimeout = 10000;
const initialTimeout = 250;
let timeout = 250;

export function NotificationSocketProvider({
  children,
}: WebSocketProviderProps) {
  const [socket, setSocket] = useState<Socket>(null);

  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);

  const { playNotificationSound } = usePlayNotificationSound();

  const connect = () => {
    if (!AuthClientStore.getToken()) {
      return;
    }
    const ws = SocketConfig.createSocket(AuthClientStore.getToken() ?? '');

    ws.onmessage = (event) => {
      const parsedData: { event: unknown; data: unknown } = JSON.parse(
        event.data
      );

      if (parsedData.event === SocketEvent.ChatMessageReceived) {
        eventEmitter.emit(EVENTS.WS.CHAT_MESSAGE_RECEIVED, parsedData.data);
        if (!window.location.pathname.includes('/chat-messages')) {
          playNotificationSound();
          dispatch(incrementMessagesCount());
        }
      } else if (parsedData.event === SocketEvent.NotificationReceived) {
        eventEmitter.emit(EVENTS.WS.NOTIFICATION_RECEIVED, parsedData.data);
        playNotificationSound();
        dispatch(incrementNotificationsCount());
      } else if (parsedData.event === SocketEvent.GetAllChannelMessages) {
        eventEmitter.emit(
          EVENTS.WS.GET_ALL_CHANNEL_MESSAGES,
          JSON.parse(parsedData.data as string)
        );
      } else if (parsedData.event === SocketEvent.ParticipantLastSeenUpdated) {
        eventEmitter.emit(
          EVENTS.WS.PARTICIPANT_LAST_SEEN_UPDATED,
          parsedData.data
        );
      } else if (parsedData.event === SocketEvent.MessageDeleted) {
        eventEmitter.emit(EVENTS.WS.MESSAGE_DELETED, parsedData.data);
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket Error ', error);
    };

    ws.onopen = () => {
      timeout = initialTimeout;
      console.info('WebSocket connection opened.');
      setSocket(ws);
    };

    ws.onclose = (e) => {
      console.info('WebSocket connection closed.');
      setSocket(null);
      if (!e.wasClean || isAuthenticated) {
        const newTimeout = Math.min(maxTimeout, (timeout += timeout));
        console.info(
          `Retrying WebSocket connection in ${newTimeout / 1000} seconds`
        );
        setTimeout(connect, newTimeout);
      }
    };
  };

  useEffect(() => {
    if (!isAuthenticated) {
      if (socket) {
        socket.close();
      }
      return;
    }

    connect();
  }, [isAuthenticated]);

  return (
    <WebSocketContext.Provider value={socket}>
      {children}
    </WebSocketContext.Provider>
  );
}
