import styled from 'styled-components';
import { Size } from '../../../../../styles/theme/types';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const MainContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

export const IconContainer = styled.div<{
  $iconColor: string;
  $iconSize: Size;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: ${({ $iconSize }) => $iconSize.width};
    height: ${({ $iconSize }) => $iconSize.height};
    path {
      fill: ${({ theme }) => theme.palette.main.marine};
      fill: ${({ $iconColor }) => $iconColor};
    }
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
`;

export const Text = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 400;
  font-size: ${({ theme }) => theme.typography.pxToRem(2)};
  line-height: ${({ theme }) => theme.typography.pxToRem(6)};
  color: ${({ theme }) => theme.palette.main.black};
  @media only screen and (max-width: 480px) {
    font-size: ${({ theme }) => theme.typography.pxToRem(0)};
    line-height: ${({ theme }) => theme.typography.pxToRem(4)};
  }
`;

export const BoldText = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.pxToRem(2)};
  line-height: ${({ theme }) => theme.typography.pxToRem(6)};
  color: ${({ theme }) => theme.palette.main.black};
  @media only screen and (max-width: 480px) {
    font-size: ${({ theme }) => theme.typography.pxToRem(0)};
    line-height: ${({ theme }) => theme.typography.pxToRem(4)};
  }
`;
