export default {
  general: {
    startHere: 'Începe aici',
    explore: 'Explorează',
    explorer: 'Explorer',
    exminder: 'Exminder',
    submit: 'Trimite',
    continue: 'Continuă',
    skip: 'Sari peste',
    save: 'Salvează',
    edit: 'Editează',
    start: 'Începe',
    clear: 'Șterge',
    apply: 'Aplică',
    reset: 'Resetează',
    homepage: 'Pagina principală',
    goToApp: 'Mergi la aplicație',
    back: 'Înapoi',
    next: 'Înainte',
    yes: 'Da',
    no: 'Nu',
    statistics: 'Statistici',
    milestones: 'Repere',
    tryAgain: 'Încearcă din nou',
    buy: 'Cumpără',
    join: 'Alătură-te',
    interested: 'Sunt interesat',
    expired: 'Expirată',
    cancel: 'Anulează',
    confirm: 'Confirmă',
    leave: 'Părăsește',
    accept: 'Acceptă',
    reject: 'Refuză',
    accepted: 'Acceptat',
    rejected: 'Refuzat',
    discount: 'Reducere',
    experience: 'Experiență',
    seeLess: 'Vezi mai puțin',
    seeMore: 'Vezi mai mult',
    message: 'Mesaj',
    messageExminder: 'Mesaj Exminder',
    messageExplorer: 'Mesaj Explorer',
    messageExminderSpacing: 'Mesaj\nExminder',
    messageExplorerSpacing: 'Mesaj\nExplorer',
    requirements: 'Cerințe',
    benefits: 'Beneficii',
    testimonials: 'Testimoniale',
    seeProfile: 'Vezi profilul',
    quickShare: 'Partajare rapidă',
    seeExperience: 'Vezi Experiența',
    seeExperienceSpacing: 'Vezi\nExperiența',
    buyExperience: 'Cumpără Experiența',
    scrollDownToSeeMore: 'Vezi mai jos mai multe detalii',
    loading: 'Se încarcă...',
    typeYourAnswer: 'Scrie răspunsul tău...',
    noOptionsFound: 'Nu s-au găsit opțiuni...',
    from: 'DE LA',
    startPrice: 'Preț de start',
    auction: 'Licitație',
    confirmAction: 'Ești sigur că vrei să confirmi acțiunea?',
    popularPerson: 'Persoană populară',
    report: 'Raportează',
    shareExperience: 'Vezi această Experiență pe Exminds:',
    shareExperienceUrl:
      'Hei, tu! Descarcă aplicația Exminds și vezi această Experiență: "{{experienceName}}" și Profilul unde poți găsi multe altele! {{experienceUrl}}',
    shareOwnExperienceUrl:
      'Hei, tu! Descarcă aplicația Exminds și vezi Experiența mea: "{{experienceName}}" și Profilul meu unde poți găsi multe altele! {{experienceUrl}}',
    time: {
      hour: 'oră',
      minute: 'minut',
      second: 'secundă',
      hours: 'ore',
      minutes: 'minute',
      seconds: 'secunde',
    },
    minimum: 'minimum',
    maximum: 'maximum',
    characters: 'de caractere',
    off: 'reducere',
    attachment: 'Atașament',
  },
};
