import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authRequest, LoginData } from 'api/AuthRequest';
import { userRequest } from 'api/UserRequest';
import { AppRole, OnboardingStatus } from '../../api/types/user';
import ExminderViewClientStore from '../../clientStore/ExminderViewClientStore';
import AuthClientStore from '../../clientStore/AuthClientStore';

export const login = createAsyncThunk(
  'auth/login',
  async (loginData: LoginData) => {
    const loginResponse = await authRequest.login(loginData);
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler(
        'NativeLogin',
        AuthClientStore.getToken()
      );
    }

    return loginResponse;
  }
);

export const tokenLogin = createAsyncThunk(
  'auth/token_login',
  async (token: string) => {
    const loginResponse = await authRequest.tokenLogin(token);
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler(
        'NativeLogin',
        AuthClientStore.getToken()
      );
    }

    return loginResponse;
  }
);

export const logout = createAsyncThunk('auth/logout', () => {
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler(
      'NativeLogout',
      AuthClientStore.getToken()
    );
  }
  authRequest.logout();
  ExminderViewClientStore.removeExminderView();
});

export const skipOnboardingProcess = createAsyncThunk('onboarding/skip', () => {
  userRequest.skipOnboardingProcess();
});

export const me = createAsyncThunk('auth/me', () => authRequest.me());

interface SellerAsCompanyDataSnapshot {
  country: string;
  externalAccount: {
    object: string;
    country: string;
    currency: string;
    accountNumber: string;
    bankName: string;
  };
  businessType: 'company';
  company: {
    name: string;
    taxId: string;
    registrationNumber: string;
    address: {
      country: string;
      state?: string;
      city: string;
      line1: string;
      postalCode: string;
    };
  };
}

type Metadata = {
  stripe: {
    seller: {
      accountId: string;
      created: number;
      type: string;
    };
    sellerAsCompanyDataSnapshot?: SellerAsCompanyDataSnapshot;
  };
  financialSettings: {
    dontSendExminderCompanyToExplorerInvoiceEmail: boolean;
  };
};

type City = {
  id: string;
  name: string;
  country: {
    id: string;
    name: string;
    iso2: string;
    iso3: string;
  };
};

export interface ProfilePicture {
  id: string;
  path: string;
  name: string;
  extension: string;
  size: number;
  mimetype: string;
  uploaderId: string;
}

export interface AuthState {
  user: {
    id: string | null;
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    phone: string | null;
    birthdate: string | null;
    city: City | null;
    profilePicture?: ProfilePicture;
    isOnboardingSkipped?: boolean;
    hasReferralAccount?: boolean;
    onboardingStatus?: OnboardingStatus;
    emailIsVerified?: boolean;
    isPhoneVerified: boolean | undefined;
    metadata?: Metadata;
    nativeLanguage?: { id: string; name: string; code: string };
    languages?: { id: string; name: string; code: string }[];
    role?: {
      name: string;
    };
    unread: {
      notifications: number;
      messages: number;
    };
    slug: string;
  };
  isAuthenticated: boolean;
}

const initialState: AuthState = {
  user: {
    id: null,
    email: null,
    firstName: null,
    lastName: null,
    phone: null,
    birthdate: null,
    city: null,
    isOnboardingSkipped: undefined,
    onboardingStatus: undefined,
    emailIsVerified: undefined,
    isPhoneVerified: undefined,
    metadata: undefined,
    profilePicture: undefined,
    nativeLanguage: undefined,
    languages: undefined,
    unread: {
      notifications: 0,
      messages: 0,
    },
    slug: '',
  },
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserOnboardingStatus(state, action: PayloadAction<OnboardingStatus>) {
      state.user.onboardingStatus = action.payload;
    },
    setUserRole(state, action: PayloadAction<AppRole>) {
      return {
        ...state,
        user: {
          ...state.user,
          role: {
            ...state.user.role,
            name: action.payload,
          },
        },
      };
    },
    incrementNotificationsCount(state) {
      state.user.unread.notifications += 1;
    },
    decrementNotificationsCount(state) {
      state.user.unread.notifications -= 1;
    },
    incrementMessagesCount(state) {
      state.user.unread.messages += 1;
    },
    updateMessagesCount(state, action: PayloadAction<number>) {
      state.user.unread.messages -= action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => ({
      ...initialState,
      isAuthenticated: false,
    }));
    builder.addCase(me.fulfilled, (state: AuthState, action) => {
      state.user = { ...state.user, ...action.payload };
      state.isAuthenticated = true;
    });
    builder.addCase(skipOnboardingProcess.fulfilled, (state: AuthState) => {
      state.user = { ...state.user, isOnboardingSkipped: true };
    });
  },
});

export const {
  setUserOnboardingStatus,
  setUserRole,
  incrementNotificationsCount,
  incrementMessagesCount,
  decrementNotificationsCount,
  updateMessagesCount,
} = authSlice.actions;

export default authSlice.reducer;
