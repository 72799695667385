import { useInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { popularPersonRequest } from 'api/PopularPersonRequest';
import { queryKeys } from '../../constants/queryKeys';

function useGetPopularPersons(
  queryOptions: {
    limit: number;
    searchText?: string;
  },
  enabled?: boolean
) {
  const QUERY_KEY = [queryKeys.popularPersons, queryOptions];

  const { limit, searchText } = queryOptions;

  const fetchPopularPersons = async ({ pageParam = 0 }) =>
    popularPersonRequest.getPopularPersonSearched({
      limit,
      offset: pageParam,
      searchText,
    });

  const { t } = useTranslation();

  return useInfiniteQuery({
    queryKey: QUERY_KEY,
    queryFn: fetchPopularPersons,
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (
        lastPage.results.length === 0 ||
        allPages.length === lastPage.total ||
        lastPage.total <= limit
      ) {
        return undefined;
      }
      return lastPageParam + 1;
    },

    select: (data) => ({
      pages: data.pages
        .flatMap((res) => res.results)
        .map((user) => ({
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          role: t(`general.popularPerson`),
          profilePhoto: user.profilePictureUrl,
        })),
      pageParams: data.pageParams,
    }),
    enabled: enabled || !!searchText,
  });
}
export default useGetPopularPersons;
