import { JSX, SVGProps } from 'react';

export default function ArrowLeftIcon(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.375 7.12477H4.93873L9.47623 2.60102C9.73515 2.3421 9.88061 1.99093 9.88061 1.62477C9.88061 1.2586 9.73515 0.907434 9.47623 0.648517C9.21731 0.389599 8.86615 0.244141 8.49998 0.244141C8.13382 0.244141 7.78265 0.389599 7.52373 0.648517L0.64873 7.52352C0.523549 7.65428 0.425422 7.80848 0.35998 7.97727C0.222455 8.31203 0.222455 8.68751 0.35998 9.02227C0.425422 9.19105 0.523549 9.34525 0.64873 9.47602L7.52373 16.351C7.65155 16.4799 7.80363 16.5822 7.97119 16.652C8.13874 16.7218 8.31846 16.7577 8.49998 16.7577C8.6815 16.7577 8.86122 16.7218 9.02877 16.652C9.19633 16.5822 9.34841 16.4799 9.47623 16.351C9.60511 16.2232 9.7074 16.0711 9.77721 15.9036C9.84701 15.736 9.88295 15.5563 9.88295 15.3748C9.88295 15.1933 9.84701 15.0135 9.77721 14.846C9.7074 14.6784 9.60511 14.5263 9.47623 14.3985L4.93873 9.87477H15.375C15.7397 9.87477 16.0894 9.7299 16.3473 9.47204C16.6051 9.21418 16.75 8.86444 16.75 8.49977C16.75 8.13509 16.6051 7.78536 16.3473 7.5275C16.0894 7.26963 15.7397 7.12477 15.375 7.12477Z"
        fill="#0082AA"
      />
    </svg>
  );
}
