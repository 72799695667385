export default {
  experienceInviteGuest: {
    title: 'Tell us who is going to join you!',
    description:
      'Type in the email address of the person you intend to share this Experience with.' +
      '\n\n' +
      'They will receive all the future steps on the provided address.',
    input: {
      placeholder: 'Type the email address...',
    },
    button: 'Send',
    info: {
      title:
        'Bring a friend to experience with you and split the price in half!',
      description:
        'Experiences are meant to be shared— whether you split the cost or surprise a friend, after purchase, check your email to add their address.',
    },
    splitPrice: 'Split price with a friend',
  },
};
