export default {
  hobbies: {
    letUsKnowMore: 'Ce hobby-uri ai?',
    chooseUpToSevenHobbies:
      'Alege până la șapte opțiuni care te reprezintă cel mai bine.',
    editYourHobbies: 'Editează hobby-urile',
    leadership: '🧑‍💼 Leadership',
    sports: '🎾 Sport',
    cooking: '🍝 Gătit',
    hiking: '🧗 Drumeții',
    music: '🎶 Muzică',
    arts_and_crafts: '🎨 Artă',
    agriculture: '🥕 Agricultură',
    fishing: '🎣 Pescuit',
    graphic_design: '🖥️ Design grafic',
    public_speaking: '🎤 Public speaking',
    reading: '📚 Lectură',
    architecture: '🏠 Arhitectură',
    coding: '🧑‍💻 IT & Programare',
    gardening: '⛲️ Grădinărit',
    writing: '✏️ Copywriting',
    dancing: '💃 Dans',
    video_editing: '🎥 Editare video',
    gaming: '🕹️ Gaming',
    volunteering: '🙋 Voluntariat',
    acting: '🎭 Actorie',
    yoga: '🧘 Yoga',
    swimming: '🏊 Înot',
    karting: '🏎️ Karting',
    ai: '‍🦾 Inteligență artificială',
    streaming: '🎙️ Streaming',
    photography: '📸 Fotografie',
    travelling: '🏝️ Călătorii',
    beauty: '💄 Îngrijire personală',
    languages: '🇰🇷 Limbi străine',
    back: 'Înapoi',
    next: 'Înainte',
  },
};
