import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedCommunityGuidelinesPage = lazy(
  () =>
    import(
      '../components/smart/pages/communityGuidelines/ConnectedCommunityGuidelinesPage'
    )
);

const COMMUNITY_GUIDELINES: Route = {
  component: ConnectedCommunityGuidelinesPage,
  path: PATH.COMMUNITY_GUIDELINES,
  isProtected: false,
};

export default [COMMUNITY_GUIDELINES];
